






























































import Vue from 'vue';
import { Badge, Card, Row, Table, TableColumn, Tag } from 'element-ui';
import FaqTypesLabel from '@/domain/faq/components/controls/FaqTypesLabel.vue';

export default Vue.extend({
  name: 'FaqListComponent',
  components: {
    FaqTypesLabel,
    [Badge.name]: Badge,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Card.name]: Card,
    [Tag.name]: Tag,
    [Row.name]: Row,
  },
  props: {
    faqList: {
      type: Array,
    },
    pageInfo: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    cellStyle(): { padding: string } {
      return { padding: '0' };
    },
    pageClick(bvEvent: any, page: number): void {
      this.$emit('changePage', page);
    },
  },
});
