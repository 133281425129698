










import Vue from 'vue';
import Spinner from '@/components/Spinner.vue';
import axios from 'axios';
import { getLegacyApiUrl } from '@/env';
import FaqListComponent from '@/domain/faq/components/FaqListComponent.vue';

export default Vue.extend({
  name: 'FaqContainer',
  components: {
    FaqListComponent,
    Spinner,
  },
  data() {
    return {
      loading: true,
      faqs: [],
      totalCount: 0,
      page: 1,
      size: 15,
    };
  },
  async created() {
    await (this as any).legacyFaqList();
  },
  methods: {
    async legacyFaqList(): Promise<void> {
      const skip = (this.page - 1) * this.size;
      const result = await axios({
        url: `${getLegacyApiUrl()}/Faqs`,
        method: 'GET',
        params: {
          filter: {
            limit: this.size,
            skip,
            order: 'id ASC',
            where: {
              category: {
                inq: [
                  'host-common',
                  'host-make-frip',
                  'host-before-open',
                  'host-after-open',
                  'host-bill',
                  'host-etc',
                ],
              },
            },
          },
        },
      });

      const totalCountResult = await axios({
        url: `${getLegacyApiUrl()}/Faqs/count`,
        method: 'GET',
        params: {
          where: {
            category: {
              inq: [
                'host-common',
                'host-make-frip',
                'host-before-open',
                'host-after-open',
                'host-bill',
                'host-etc',
              ],
            },
          },
        },
      });

      this.faqs = result?.data.map((faq: any) => {
        return {
          id: faq.id,
          question: faq.question,
          answer: faq.answer,
          type: faq.category.toUpperCase().replaceAll('-', '_'),
        };
      });
      this.totalCount = totalCountResult?.data.count;
      this.loading = false;
    },
    async pageClick(page: number): Promise<void> {
      this.page = page;
      await (this as any).legacyFaqList();
    },
  },
});
