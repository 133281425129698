




import Vue from 'vue';
import { FaqType, FaqTypeLabel } from '@/domain/faq/model/FaqTypes';

export default Vue.extend({
  name: 'FaqTypesLabel',
  props: {
    status: {
      type: String,
    },
  },
  computed: {
    info: function (): { type: string; label: string; value?: FaqType } {
      return (
        FaqTypeLabel.find(i => i.value.toString() === this.status) ?? {
          type: '',
          label: '',
        }
      );
    },
  },
  created() {
    console.log(this.status);
  },
});
